import React from "react"
import { Link } from "gatsby"
import Layout from "../components/Layout"
import dataManagement from "../images/data-quality.svg"
import dataSecurity from "../images/data-security.svg"
const Data = () => {
  return (
    <Layout>
      <main id='main' style={{ backgroundColor: "rgb(36, 41, 67)" }}>
        <h2 className="text-center text-4xl my-16">Data Management</h2>
        <div className="grid grid-cols-3 grid-auto-rows px-16 md:px-32 lg:px-64">
          <div className="col-start-1 col-span-3 grid-row-1 md:col-span-2 row-start-1 my-16 pt-5">
            <h2 className="text-2xl font-bold pl-5">Data Quality</h2>
            <p className="p-5 text-xl">
              Applying best practices to data management helps in improving data
              quality and access. Therefore, better search results are obtained
              in a company with better and faster access to the organization’s
              data, which can aid in decision making.
            </p>
          </div>

          <div className="col-start-1 col-span-3 md:col-span-1 row-start-2 md:row-start-1 my-16">
            <img
              src={dataManagement}
              style={{
                filter:
                  "invert(99%) sepia(68%) saturate(247%) hue-rotate(347deg) brightness(120%) contrast(100%)",
              }}
              className="opacity-75"
            />
          </div>

          <div className="col-start-1 col-span-3 md:col-start-2 row-start-3 md:row-start-2 my-16">
            <h2 className="text-2xl font-bold pl-5">Data Security</h2>
            <p className="p-5 text-xl">
              Keeping your data secure has never been more important and proper
              data management helps in ensuring that information is never lost
              and is protected inside the organization. Data security is an
              essential part of data management. It protects employees and
              companies from malicious actors.
            </p>
          </div>
          <div className="col-start-1 col-span-3 md:col-span-1 row-start-4 md:row-start-2 my-16">
            <img
              src={dataSecurity}
              style={{
                filter:
                  "invert(99%) sepia(68%) saturate(247%) hue-rotate(347deg) brightness(120%) contrast(100%)",
              }}
              className="opacity-75"
              alt=""
            />
          </div>
          <div className="col-start-1 col-span-3 md:col-span-2 my-16">
            <h2 className="text-2xl">Other Data Management Services</h2>

            <div className="w-full flex ">
              <ol className="list-disc list-inside">
                <li>Data Modeling</li>
                <li>Data Integration</li>
                <li>Data Governance</li>

              </ol>
            </div>
          </div>
        </div>
      </main>
    </Layout>
  )
}

export default Data
